/* eslint-disable import/prefer-default-export */
import { HS_CHATFLOW_PRIVATE_ENQUIRY } from "../constants";
import _trackEvent from "../../tracking";
export var handlePrivateEnquiry = function handlePrivateEnquiry(event, sdkReady, openChat) {
  var trackEvent = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _trackEvent;
  var productName = event.detail && event.detail.productName;
  trackEvent("HANDLE_PRIVATE_ENQUIRY - Hubspot", {
    SDK_ready: sdkReady
  });

  if (sdkReady) {
    openChat(HS_CHATFLOW_PRIVATE_ENQUIRY);
  } else {
    var emailTo = "hello@muchbetteradventures.com";
    var subject = productName ? "Private Group Enquiry: ".concat(productName) : "Private Group Enquiry";
    window.open("mailto:".concat(emailTo, "?cc=").concat(emailTo, "&subject=").concat(subject), "_blank").focus();
  }
};