/* eslint-disable import/prefer-default-export */
import { HS_CHATFLOW_MORE_PRODUCT_INFORMATION } from "../constants";
import _trackEvent from "../../tracking";
export var handleMoreProductInformation = function handleMoreProductInformation(event, sdkReady, openChat) {
  var trackEvent = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _trackEvent;
  var productName = event.detail && event.detail.productName;
  trackEvent("HANDLE_MORE_PRODUCT_INFORMATION - Hubspot", {
    SDK_ready: sdkReady
  });

  if (sdkReady) {
    openChat(HS_CHATFLOW_MORE_PRODUCT_INFORMATION);
  } else {
    var emailTo = "hello@muchbetteradventures.com";
    var subject = productName ? "Product Information Request: ".concat(productName) : "Product Information Request";
    window.open("mailto:".concat(emailTo, "?cc=").concat(emailTo, "&subject=").concat(subject), "_blank").focus();
  }
};