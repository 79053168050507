/* eslint-disable import/prefer-default-export */
import _trackEvent from "../../tracking";
export var handleSupportNeeded = function handleSupportNeeded(knowledgeBaseUrl, sdkReady, openChat) {
  var trackEvent = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _trackEvent;
  trackEvent("HANDLE_SUPPORT_NEEDED - Hubspot", {
    SDK_ready: sdkReady
  });

  if (sdkReady) {
    openChat();
  } else {
    window.open(knowledgeBaseUrl, "_blank").focus();
  }
};