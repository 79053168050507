/* eslint-disable import/prefer-default-export */
import { EVENT_MORE_PRODUCT_INFORMATION, EVENT_PRIVATE_ENQUIRY, EVENT_SUPPORT_NEEDED, HS_CHATFLOWS } from "./constants";
import { handleMoreProductInformation } from "./eventHandlers/moreProductInformation";
import { handlePrivateEnquiry } from "./eventHandlers/privateEnquiry";
import { handleSupportNeeded } from "./eventHandlers/supportNeeded";

var setChatflowQueryString = function setChatflowQueryString(specificChatflow) {
  var newChatflow = specificChatflow;

  if (specificChatflow && !HS_CHATFLOWS.includes(specificChatflow)) {
    console.error("Couldnt open chatflow \"".concat(specificChatflow, "\". Falling back to default chatflow"));
    newChatflow = undefined;
  }

  var url = new URL(window.location);
  HS_CHATFLOWS.forEach(function (possibleChatflow) {
    if (url.searchParams.has(possibleChatflow)) url.searchParams.delete(possibleChatflow);
  });

  if (newChatflow) {
    url.searchParams.set(newChatflow, 1);
  }

  window.history.pushState({}, "", url);
};

var isSdkLoaded = function isSdkLoaded() {
  return typeof window !== "undefined" && window.HubSpotConversations && window.HubSpotConversations.widget.status().loaded;
};

var openHubspotChatflow = function openHubspotChatflow(newChatflow) {
  setChatflowQueryString(newChatflow);
  window.HubSpotConversations.widget.remove();
  window.HubSpotConversations.widget.load();
  var intervalId = setInterval(function () {
    if (window.HubSpotConversations.widget.status().loaded === true) {
      window.HubSpotConversations.widget.open();
      clearInterval(intervalId);
    }
  }, 200);
};

var logCustomEvent = function logCustomEvent(eventName, event) {
  console.log("Calling custom event -- ", eventName, event);
};

var overrideZIndex = function overrideZIndex(zIndex) {
  var style = document.createElement("style");
  style.innerHTML = "\n    #hubspot-messages-iframe-container {\n      z-index: ".concat(zIndex, " !important;\n    }\n  ");
  document.head.appendChild(style);
};

var liveChatInit = function liveChatInit(knowledgeBaseUrl) {
  var zIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1299;
  window.addEventListener(EVENT_SUPPORT_NEEDED, function (event) {
    logCustomEvent(EVENT_SUPPORT_NEEDED, event);
    handleSupportNeeded(knowledgeBaseUrl, isSdkLoaded(), openHubspotChatflow);
  });
  window.addEventListener(EVENT_PRIVATE_ENQUIRY, function (event) {
    logCustomEvent(EVENT_PRIVATE_ENQUIRY, event);
    handlePrivateEnquiry(event, isSdkLoaded(), openHubspotChatflow);
  });
  window.addEventListener(EVENT_MORE_PRODUCT_INFORMATION, function (event) {
    logCustomEvent(EVENT_MORE_PRODUCT_INFORMATION, event);
    handleMoreProductInformation(event, isSdkLoaded(), openHubspotChatflow);
  });
  overrideZIndex(zIndex);
};

export { liveChatInit };